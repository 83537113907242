import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const tours = [
  {
    title: 'Las Vegas Night City Tour',
    description: 'The ultimate sightseeing experience in our luxurious vehicles.',
    details: [
      'Experience the magic of Las Vegas at night with our 2-hour city tour! Starting from your hotel, we\'ll journey down the iconic Las Vegas Strip, taking in the dazzling lights and vibrant energy. We\'ll stop at the famous "Welcome to Fabulous Las Vegas" sign for a photo op, then return you to your hotel, ensuring an unforgettable night in the Entertainment Capital of the World!'
    ],
    image: require('./../assets/las_vegas_night_city_tour.png'),
  },
  {
    title: 'Freemont Street - Downtown Las Vegas Tour',
    description: 'The ultimate sightseeing experience in our luxurious vehicles.',
    details: [
      'Discover the best of Las Vegas with our 4-hour city tour! Starting from your hotel, we\'ll first visit the iconic "Welcome to Fabulous Las Vegas" sign for a memorable photo stop. Next, we\'ll head to the legendary Fremont Street, where you can experience the historic heart of the city and its vibrant street shows. From there, we\'ll take a scenic drive along the Las Vegas Strip, showcasing its world-famous lights, luxury resorts, and attractions. The tour concludes with a comfortable ride back to your hotel, ensuring you capture the essence of Vegas in just a few hours!'
    ],
    image: require('./../assets/freemont_street_downtown_las_vegas_tour.png'),
  },
  {
    title: 'Shopping Tour',
    description: 'Explore the beauty of Red Rock Canyon with our guided tours.',
    details: [
      'Indulge in a shopping adventure with our exclusive Las Vegas Shopping Tour! Enjoy convenient roundtrip transportation from your hotel to one of the city\'s top outlets or malls, where you can explore a wide range of designer brands, luxury goods, and unbeatable deals. Please note, the roundtrip must be scheduled in advance to ensure a smooth and stress-free experience. After your shopping spree, we\'ll bring you back to your hotel, leaving you with bags full of treasures and memories of a perfect day in Las Vegas!'
    ],
    image: require('./../assets/shopping_tour.png'),
  },
  {
    title: 'Red Rock Canyon Tour',
    description: 'The ultimate sightseeing experience in our luxurious vehicles.',
    details: [
      'Escape the city and explore the stunning natural beauty of Red Rock Canyon with our 4-hour tour! We\'ll pick you up from your hotel and take you on a scenic drive through the breathtaking desert landscape, with plenty of time to admire the towering red cliffs and unique rock formations. You\'ll have opportunities to stop for photos, take in the incredible views, and learn about the area\'s geology and history. After your adventure, we\'ll comfortably return you to your hotel, leaving you with unforgettable memories of this natural wonder just outside Las Vegas!'
    ],
    image: require('./../assets/red_rock_canyon.png'),
  },
  {
    title: 'Grand Canyon West Rim Tour',
    description: 'Explore the beauty of Red Rock Canyon with our guided tours.',
    details: [
      'Join us for a spectacular 7-8 hour tour of the Grand Canyon, complete with hotel pickup and drop-off for your convenience. Your tour includes entrance tickets, giving you access to some of the most breathtaking viewpoints in the world. For an even more memorable experience, you have the option to add the thrilling Skywalk—a glass bridge over the canyon—or a meal to your package for an additional cost. After a day of exploration and awe-inspiring sights, we’ll comfortably bring you back to your hotel, ensuring an unforgettable Grand Canyon adventure!'
    ],
    image: require('./../assets/grand_canyon_west_rim_tour.png'),
  },
  {
    title: 'Hoover Dam Tour',
    description: 'Explore the beauty of Red Rock Canyon with our guided tours.',
    details: [
      'Discover the marvel of engineering that is the Hoover Dam with our 4-hour tour! We\'ll pick you up from your hotel and take you on a scenic drive to this iconic landmark, where you\'ll have time to explore the dam and its surroundings. While this tour does not include entrance to the Visitor Center, you\'ll still have plenty of opportunities to take in the breathtaking views, snap photos, and learn about the history and significance of the dam. After your visit, we\'ll return you to your hotel, providing a convenient and memorable experience.'
    ],
    image: require('./../assets/hoover_dam_tour.png'),
  },
  {
    title: '66 Route Tour',
    description: 'The ultimate sightseeing experience in our luxurious vehicles.',
    details: [
      'Take a trip down memory lane with our 6-hour Route 66 tour! Enjoy convenient hotel pickup as we guide you through some of the most iconic stops along this historic highway. You\'ll have time to explore vintage landmarks, snap photos of classic roadside attractions, and soak in the nostalgic charm of Route 66. While the tour does not include entrance to any museums, you\'ll still experience the rich history and culture of America\'s most famous road. After a day of adventure, we\'ll return you to your hotel, making this a perfect way to experience the legendary Route 66!'
    ],
    image: require('./../assets/66_route.png'),
  },
  {
    title: 'Mount Charleston Tour',
    description: 'Explore the beauty of Red Rock Canyon with our guided tours.',
    details: [
      'Escape to the serene beauty of Mount Charleston with our 6-hour tour, offering a perfect retreat from the city! We’ll pick you up from your hotel and take you on a scenic drive through the stunning landscapes of the Spring Mountains. During the winter months, if snow conditions allow, we’ll stop at a ski station where you can enjoy the snowy wonderland or simply take in the breathtaking views. Whether you\'re visiting for the snow or the tranquil mountain atmosphere, this tour provides a refreshing escape. After your adventure, we’ll return you to your hotel, leaving you with unforgettable memories of nature\'s beauty!'
    ],
    image: require('./../assets/mount_charleston.png'),
  }
];


const TourCard = ({ tour }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          alt={tour.title}
          height="200"
          image={tour.image}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            padding: '8px',
          }}
        >
          <Typography variant="h6">{tour.title}</Typography>
        </Box>
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {tour.description}
        </Typography>
        <Box
          sx={{
            maxHeight: expanded ? 'none' : '60px',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          {tour.details.map((detail, index) => (
            <Typography key={index} variant="body2" color="textSecondary">
              • {detail}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button component={Link}
          to={'/contact'}
          variant="contained"
          color="warning"
          fullWidth
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#d87a16',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#b36911',
            },
          }}
        >
          Get a Quote
        </Button>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ marginLeft: 2, transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

const TourList = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

  return (
    <Container sx={{ paddingY: 4 }}>
      <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', marginBottom: 4, color: '#333' }}>
        Las Vegas Tours
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginBottom: 4 }}>
        The ultimate sightseeing experience in our luxurious vehicles. Tailored to your specific needs to please the most discerning traveler.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {tours.map((tour, index) => (
          <Grid item xs={12} md={6} key={index}>
            <TourCard tour={tour} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TourList;
