import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Box, Divider } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import './CarCardStyles.css';  // Importe seu CSS personalizado

const CarCard = ({ car }) => {
  return (
    <Card sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        borderRadius: 3, 
        boxShadow: 3, 
        padding: 2, 
        backgroundColor: '#f7f2e8', 
        border: '1px solid #e0e0e0', 
        marginBottom: 4, 
        transition: 'transform 0.3s ease', 
        '&:hover': {
          transform: 'scale(1.02)', 
        },
    }}>
      <Box sx={{ position: 'relative' }}>
        <Carousel 
          showThumbs={false} 
          infiniteLoop 
          showStatus={false}
          showIndicators={true}
          useKeyboardArrows 
          autoPlay={false}
        >
        {car.images.map((image, index) => (
  <div key={index} style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: '#f7f2e8', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
  }}>
    <img 
      src={image} 
      alt={`${car.title} ${index + 1}`} 
      style={{ 
        objectFit: 'cover',
        width: '100%',
        height: '250px',
      }} 
    />
  </div>
))}
        </Carousel>
        <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex', alignItems: 'center', color: '#f39c12' }}>
          {[...Array(5)].map((_, index) => (
            <StarIcon key={index} fontSize="small" />
          ))}
        </Box>
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          {car.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
          {car.description}
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
              <PersonIcon sx={{ verticalAlign: 'middle', marginRight: 0.5 }} /> {car.passengers}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {car.luggage}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Box sx={{ paddingTop: 0 }}>
        <Button component={Link} 
            to={'/contact'} variant="contained" color="warning" fullWidth sx={{ fontWeight: 'bold', borderRadius: 1, backgroundColor: '#d87a16' }}>
          GET A QUOTE
        </Button>
      </Box>
    </Card>
  );
};

export default CarCard;
