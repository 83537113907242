import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import GroupIcon from "@mui/icons-material/Group";
import TourIcon from "@mui/icons-material/Tour";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import skylineHome from "../assets/skyline_home.jpg";
import skylineHomeLow from "../assets/skyline_home_low.png";

// Service data
const services = [
  {
    title: "Airport Transfers",
    description:
      "Swift and seamless rides to and from McCarran International Airport.",
    icon: <FlightTakeoffIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "Charter Services",
    description: "Customized transportation for groups of any size.",
    icon: <GroupIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "City Tours",
    description: "Explore Las Vegas with our knowledgeable drivers.",
    icon: <TourIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "Private Transportation",
    description:
      "Discreet and luxurious rides for VIPs and business travelers.",
    icon: <BusinessCenterIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
];

const Header = () => {

  const [bgImage, setBgImage] = useState(skylineHomeLow); // Start with the low-res image

  // Once the component is mounted, load the high-res image
  useEffect(() => {
    const img = new Image();
    img.src = skylineHome;
    img.onload = () => {
      setBgImage(skylineHome); // Replace with high-res image once it's loaded
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative", // Position relative for pseudo-element positioning
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        padding: "40px 0",
        marginLeft: "calc(-50vw + 50%)",
        "&::before": {
          content: '""', // Required for pseudo-elements
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity
          zIndex: 1, // Place the overlay below the content
        },
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 0, mb: 2 }}>
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <Typography
            variant="h4"
            color="white"
            component="h1"
            fontWeight="bold"
          >
            Welcome to
          </Typography>
          <Typography
            sx={{ mt: 1, mb: 1 }}
            variant="h3"
            color="white"
            component="h1"
            fontWeight="bold"
          >
            V Transportation
          </Typography>
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            color="white"
            gutterBottom
          >
            Your Premier Transportation Solution in Las Vegas
          </Typography>
          <Typography variant="body1" color="white">
            Experience the ultimate in comfort, reliability, and luxury with V
            Transportation. Whether you're heading to or from the airport,
            exploring the city's vibrant attractions, or in need of private or
            group transportation, we are here to serve you. Our professional
            drivers and top-of-the-line vehicles ensure a smooth, stress-free
            journey every time.
          </Typography>
        

        {/* Services Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
              color="white"
            gutterBottom
          >
            Our Services Include:
          </Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: 3,
                    height: "220px",
                    textAlign: "center",
                    padding: 2,
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    {service.icon}
                  </Box>
                  <CardContent>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
       
        {/* Contact Section */}
        <Box sx={{ textAlign: "center", mt: 1 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
              color="white"
          >
            Book your ride today and travel with confidence.
          </Typography>
          <Typography variant="body1" color="white">
            At V Transportation, your journey is our priority.
          </Typography>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="body1" color="white">
              Contact us to schedule your transportation or to learn more about
              our services.
            </Typography>
            <Typography variant="body1" color="white">
              We look forward to serving you in Las Vegas!{" "}
            </Typography>
          </Box>
        </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
