import { Typography } from '@mui/material'
import React from 'react'

const FooterText = ({ text }) => {
  return (
    <Typography
    variant='h8'
    sx={{
      fontWeight: '400',
      pb: 1,
    }}
    >
      { text }
    </Typography>
  )
}

export default FooterText