import React from "react";
import Header from "../components/Header";
import FleetList from "../components/FleetList";
import TourList from "../components/TourList";
import ServiceList from "../components/ServiceList";
import ServiceForm from "../components/ServiceForm.jsx";
import { Container, Box } from "@mui/material";

const Home = () => {
  return (
    <Container maxWidth="lg">
      <Header />

      {/* Section for FleetList with no background */}
      <Box
        sx={{
          width: '100vw',
          padding: '40px 0',
          marginLeft: 'calc(-50vw + 50%)',
        }}
      >
        <Container maxWidth="lg">
          <FleetList />
        </Container>
      </Box>

      {/* Section for ServiceList with no background */}
      <Box
        sx={{
          width: '100vw',
          padding: '40px 0',
          marginLeft: 'calc(-50vw + 50%)',
        }}
      >
        <Container maxWidth="lg">
          <ServiceList />
        </Container>
      </Box>

      {/* Section for TourList with a different background */}
      <Box
        sx={{
          width: '100vw',
          backgroundColor: '#E5E5E5', // Different background color
          padding: '40px 0',
          marginLeft: 'calc(-50vw + 50%)',
        }}
      >
        <Container maxWidth="lg">
          <TourList />
        </Container>
      </Box>

      {/* Section for ServiceForm with no background */}
      <Box
        sx={{
          width: '100vw',
          padding: '40px 0',
          marginLeft: 'calc(-50vw + 50%)',
        }}
      >
        <Container maxWidth="lg">
          <ServiceForm />
        </Container>
      </Box>
    </Container>
  );
};

export default Home;